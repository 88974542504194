<template>
  <div class="container-box">
    <div class="main-box">
      <!-- 视频播放 -->
      <div class="video-box">
        <video
          id="player-container-id"
          preload="auto"
          playsinline
          webkit-playsinline
        >
          您的浏览器不支持 video 标签，请升级或更换其他的浏览器。
          <br />
          <a
            href="https://www.google.cn/chrome/"
            style="text-decoration: underline"
            >Google Chrome 浏览器</a
          >
          <br />
        </video>
        <div class="menu-box" :class="{ 'menu-box-open': isDrawerOpen }">
          <!-- 章节目录打卡/关闭按钮 -->
          <div class="drawer-btn" @click="isDrawerOpen = !isDrawerOpen">
            <img
              :class="{ 'rotate-img': isDrawerOpen }"
              src="@/assets/image/curriculum/open-Icon.png"
              alt=""
            />
            <span>章节目录</span>
          </div>
          <!-- 目录 -->
          <div class="menu-list">
            <h3 class="list-name">课程列表</h3>
            <a-spin class="loading" v-show="tree.length == 0" tip="加载中..." />
            <Tree :tree="tree" :sectionId="Number(sectionId)" @nodeClick="treeClick" />
          </div>
        </div>
      </div>

      <!-- 课程信息 -->
      <div class="init-box">
        <div class="title">
          <span>{{ courseDetail.courseName }}</span>
          <span class="right"
            >{{ courseDetail.categoryName }} | 视频数量：{{
              courseDetail.classHour
            }}个</span
          >
        </div>
        <div class="content">{{ courseDetail.introduction }}</div>
        <div
          class="row"
          v-if="courseDetail.startTime"
          :style="
            'background-image:url(' +
            require('@/assets/image/curriculum/20220506-141106.png') +
            ')'
          "
        >
          <span class="text">
            <em>有</em>
            <em>效</em>
            <em>期</em>
          </span>
          <span v-if="courseDetail.validDayType == 2">长期</span>
          <span v-else-if="courseDetail.startTime"
            >有效期：{{ courseDetail.startTime }} 至
            {{ courseDetail.endTime }}</span
          >
        </div>
        <div
          class="row"
          v-if="courseDetail.openStartTime"
          :style="
            'background-image:url(' +
            require('@/assets/image/curriculum/20220506-141113.png') +
            ')'
          "
        >
          <span class="text">
            <em>班</em>
            <em>级</em>
            <em>期</em>
            <em>数</em>
          </span>
          <span
            >{{ courseDetail.className }}（{{ courseDetail.openStartTime }} 至
            {{ courseDetail.openEndTime }}）</span
          >
        </div>
      </div>
      <!-- tabs -->
      <div class="tabs-box">
        <!-- 切换 -->
        <div class="tabs">
          <a
            class="tabsdiv"
            href="javascript:;"
            @click="tabIndex = 0"
            :class="tabIndex == 0 ? 'a1' : ''"
          >
            <span>课程介绍</span>
          </a>
          <a
            class="tabsdiv"
            href="javascript:;"
            @click="tabIndex = 1"
            :class="tabIndex == 1 ? 'a2' : ''"
          >
            <span>目录</span>
          </a>
          <a
            class="tabsdiv"
            href="javascript:;"
            @click="tabIndex = 2"
            :class="tabIndex == 2 ? 'a2' : ''"
          >
            <span>导师介绍</span>
          </a>
          <a
            class="tabsdiv"
            href="javascript:;"
            v-show="courseDetail.studentInstructions"
            @click="tabIndex = 3"
            :class="tabIndex == 3 ? 'a2' : ''"
          >
            <span>学习指导</span>
          </a>
          <!-- <a href="javascript:;" @click="tabIndex=4" :class="tabIndex==4?'click':''">
            <span>报名流程</span>
          </a> -->
        </div>
        <!-- 视图 -->
        <div class="content">
          <!-- 课程介绍 -->
          <a-empty
            v-if="tabIndex == 0 && !courseDetail.detail"
            style="padding-top: 50px"
            :image="require('@/assets/image/stateHint/icon_nodata.png')"
            :image-style="{
              height: '180px',
            }"
          />
          <div v-else-if="tabIndex == 0" v-html="courseDetail.detail">
          </div>
          <!-- 目录 -->
          <a-empty
            v-if="tabIndex == 1 && !tree"
            style="padding-top: 50px"
            :image="require('@/assets/image/stateHint/icon_nodata.png')"
            :image-style="{
              height: '180px',
            }"
          />
          <template v-else-if="tabIndex == 1">
            <forTree
              :tree="tree"
              :isHave="1"
              :courseId="courseDetail.courseId"
              :courseName="courseDetail.courseName"
              :sectionId="Number(sectionId)"
              @nodeClick="treeClick"
            />
          </template>
          <!-- 导师介绍 -->
          <a-empty
            v-if="tabIndex == 2 && !courseDetail.teacherList"
            style="padding-top: 50px"
            :image="require('@/assets/image/stateHint/icon_nodata.png')"
            :image-style="{
              height: '180px',
            }"
          />
          <template v-else-if="tabIndex == 2">
            <div
              v-for="item in courseDetail.teacherList"
              :key="'teacherList' + item.teacherId"
            >
              <div class="teacher">
                <div class="left">
                  <img
                    class="picture"
                    :src="item.squarePicture"
                    alt=""
                    style="width: 152px"
                  />
                  <div class="name">{{ item.name }}</div>
                  <div class="border"></div>
                  <div class="triangle"></div>
                </div>
                <div class="right">
                  <!-- <div
                    v-for="(items, indexs) in titlefors(item.title)"
                    :key="indexs"
                  >
                    <i></i>
                    <span>{{ items }}</span>
                  </div> -->
                  <swiper
                    ref="mySwiper"
                    class="titleList"
                    :options="teacherSwiperOptions"
                  >
                    <swiper-slide
                      v-for="(itemI, indexI) in item.title"
                      :key="indexI"
                    >
                      <div
                        class="titleItem"
                        v-for="(itemII, indexII) in itemI"
                        :key="indexII"
                      >
                        <span style="background: #E5E5E5"></span
                        >{{ itemII }}
                      </div>
                    </swiper-slide>
                    <div
                      v-show="item.title.length > 1"
                      v-for="(itemI, indexI) in item.title"
                      :key="indexI + 1"
                      class="swiper-pagination"
                      slot="pagination"
                    ></div>
                  </swiper>
                </div>
              </div>
              <!-- 介绍 -->
              <div class="introduction" v-html="regtxt(item.introduction)"></div>
            </div>
          </template>
          <!-- 学习指导 -->
          <a-empty
            v-if="tabIndex == 3 && !courseDetail.studentInstructions"
            style="padding-top: 50px"
            :image="require('@/assets/image/stateHint/icon_nodata.png')"
            :image-style="{
              height: '180px',
            }"
          />
          <div
            v-else-if="tabIndex == 3"
            v-html="courseDetail.studentInstructions"
          >
          </div>
          <!-- 报名流程 -->
          <a-empty
            v-if="tabIndex == 4 && !courseDetail.registrationGuide"
            style="padding-top: 50px"
            :image="require('@/assets/image/stateHint/icon_nodata.png')"
            :image-style="{
              height: '180px',
            }"
          />
          <div
            v-else-if="tabIndex == 4"
            v-html="courseDetail.registrationGuide"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import forTree from "@/components/forTree/index.vue";
import Tree from "./Tree.vue";
import playerBtn from "./course/player-btn.vue";
export default {
  mixins: [playerBtn],
  // 可用组件的哈希表
  components: { forTree, Tree },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      tabIndex: 1,
      courseDetail: {},
      videoConfig: {},
      player: null,
      tree: [],
      paused: true,
      ended: false,
      courseId: 0,
      productId: null,
      sectionId: 161,
      AllowUpdates: true,
      isDrawerOpen: false, // 目录抽屉
      appID: "1500012233",
      // 教师标签轮播
      teacherSwiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      isHave:1
    };
  },
  // 事件处理器
  methods: {
    // 清除富文本标签
    regtxt(e){
      let a= e.replace(/<[^>]+>/g,'')
      return a
    },
    /**
     * 视频播放器-初始化
     * 详细文档参考腾讯云点播
     * https://cloud.tencent.com/document/product/266/63004
     */
    initVideo({ fileID, psign }) {
      this.player = TCPlayer("player-container-id", {
        // player-container-id 为播放器容器 ID，必须与 html 中一致
        fileID: fileID, // 播放的视频 fileID（必须）
        appID: this.appID, // 点播账号的appID（必须）
        psign: psign, // 签名
        autoplay: true, // 是否自动播放
        bigPlayButton: false, // 是否显示居中的播放按钮
        controlBar: {
          progressControl: true, // 是否显示进度条
          playbackRateMenuButton: true, // 是否显示播放速率选择按钮
        },
        plugins: {
          ContinuePlay: {
            btnText: "点击继续观看",
          },
        },
      });
      // 播放器能够开始播放视频时触发
      this.player.on("canplay", () => {
        this.addPlayerBtnAfter();
        this.addPlayerBtnBefore();
        this.addPlayerBtnHtml();
      });
      // 播放开始执行
      this.player.on("play", () => {
        this.paused = false;
        this.ended = false;
        this.hidePlayerBtn();
      });
      // 播放暂停执行
      this.player.on("pause", () => {
        this.paused = true;
        this.showPlayerBtn();
      });
      // 播放结束执行
      this.player.on("ended", () => {
        this.ended = true;
        this.AllowUpdates = true;
        this.videoConfig.passStatus = 3 // 已看完
        this.showPlayerBtn();
        if(this.isHave == 1){
          // this.setPlaybackRecord(1);
        }
      });
      if(this.isHave == 1){
        // 播放进度有变化执行
        this.player.on("timeupdate", () => {
            // this.setPlaybackRecord(0);
        });
      }
      // 播放错误
      this.player.on("error", (err) => {
        // console.log(err);
      });
    },

    // 更新用户观看进度
    setPlaybackRecord(isWatch) {
      let _that = this
      if (_that.AllowUpdates) {
        // 允许更新
        _that.AllowUpdates = false;
        setTimeout(() => {
          _that.AllowUpdates = true;
        }, 5000);
        const sectionId = _that.sectionId
        const Seconds = _that.player.currentTime()
        _that.$ajax({
          url: "/hxclass-pc/course/change-progress",
          method: "put",
          params: {
            courseId: _that.courseId,
            currentSeconds: _that.player.currentTime(),
            isWatch: isWatch,
            sectionId: _that.sectionId,
            // userId: this.$store.state.userInfo.userId
          },
        }).then((res) => {
          if (res.code == "200" && res.success) {
            // _that.getTree();

            function fors(arr){
              if (arr.length !== 0) {
                arr.forEach((item) => {
                  if((item.sectionId == sectionId)){
                    if(Number(item.studyStatus) < 3){
                      item.watchProgress = Seconds / item.duration
                    }
                    if(parseInt(Seconds) == parseInt(item.duration)){
                      _that.getTree();
                    }
                  }
                  if (item.childList) {
                    item.childList = fors(item.childList);
                  }
                });
              }
              return arr;
            }
            _that.tree = fors(_that.tree)
          } else {
            _that.$message.error(res.message);
          }
        });
      }
    },

    // 重播
    onReplay() {
      // 重播时将当前进度条设为可拖动
      document.getElementById('player-container-id').getElementsByClassName('vjs-control-bar')[0].getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = ''
      this.player.currentTime(0);
      this.player.play();
    },

    // 教师列表处理
    manageTeacher() {
      if (!this.courseDetail.teacherList || this.courseDetail.teacherList.length == 0) {
        return;
      }
      let teacherlabel = [[]]; //教师标签
      let teacherSwiper = 0; //教师数组个数
      let count = 0; //计数一组放四个
      // 教师
      this.courseDetail.teacherList.map((item, index) => {
        // 标签
        item.title.split(",").map((ite, index) => {
          teacherlabel[teacherSwiper].push(ite);
          count++;
          // 超过4个,原判定是等于4就++
          // if (count == 4) {
          if (count == 4) {
            teacherlabel.push([]) //添加新分组
            teacherSwiper++ //分组+1
            count = 0 //计数清零
          }
        });
        if (item.title.split(",").length%4==0) {
           teacherSwiper--;
           teacherlabel.pop();
        }
        // 赋值
        this.$set(this.courseDetail.teacherList[index], 'title', teacherlabel)
        teacherlabel = [[]]
        teacherSwiper = 0
        count = 0
      })
    },

    // 查询课程详情
    getCourseDetail(courseId) {
      this.$ajax({
        url: `/hxclass-pc/course/teacher/${courseId}`,
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.courseDetail = res.data;
            // 教师列表处理
            this.manageTeacher()
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    // 查询视频播放信息
    getVod() {
      this.$ajax({
        url: "/hxclass-pc/course/teacher/section-vod-info",
        params: {
          userId: this.$store.state.userInfo.userId,
          courseId: this.courseId,
          sectionId: this.sectionId,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.videoConfig = res.data;
            if (this.player) {
              // 切换视频
              this.player.loadVideoByID({
                fileID: res.data.fileId,
                psign: res.data.pcfg,
                appID: this.appID,
              });
            } else {
              // 初始化视频
              this.initVideo({
                fileID: res.data.fileId, // 云点播id
                psign: res.data.pcfg, // 云点播签名
              });
            }
            // 播放器进度条是否可拖动
            if(res.data.isFast == 0 && res.data.passStatus < 3){
              document.getElementById('player-container-id').getElementsByClassName('vjs-control-bar')[0].getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = 'none'
            } else {
              document.getElementById('player-container-id').getElementsByClassName('vjs-control-bar')[0].getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = ''
            }
            // 播放器倍速按钮是否展示
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    // 播放下一节课程
    setVodCourse() {
      this.sectionId = this.videoConfig.nextSectionId;
      this.getVod();
    },

    // 查询目录信息
    getTree() {
      this.$ajax({
        url: "/hxclass-pc/course/teacher/tree",
        params: {
          courseId: this.courseId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 将目录已关闭的重置到目录数据中
          let arry = JSON.parse(localStorage.getItem("SectionIdArry")) ? JSON.parse(localStorage.getItem("SectionIdArry")) : []
          function fors(arr){
						if (arr.length !== 0) {
							arr.forEach((item) => {
								if(arry.indexOf(item.sectionId) != -1){
									item.isMenuOpen = true
                }
								if (item.childList) {
									item.childList = fors(item.childList);
								}
							});
						}
						return arr;
          }
          // console.log(fors(res.data))
          this.tree = fors(res.data);
        }
      });
    },

    // 目录点击
    treeClick(sectionId) {
      if (sectionId != this.sectionId) {
        // this.getTree()
        this.sectionId = sectionId;
        this.getVod();
      }
    },

    // 解构职称
    titlefors(e) {
      return e.split(",");
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.$store.commit("clearUserInfo");
    this.courseId = this.$route.query.courseId
    this.getCourseDetail(this.courseId);
    this.getTree();
  },
  // 生命周期-实例挂载后调用
  mounted() {
  },
  beforeDestroy() {
    sessionStorage.removeItem("selfTess");
    // localStorage.setItem('SectionIdArry', [])
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
  // 离开页面
  beforeRouteLeave(to, form, next) {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
    next();
  },
};
</script>

<style>
/* body{
  background-color: rgba(255, 255, 255, 0);
} */
</style>
<style lang="less" scoped>
.video-box {
  height: 790px;
  overflow: hidden;
  background-color: #ddd;
  position: relative;
  #player-container-id {
    height: 100%;
    width: 100%;
  }
  // 抽屉式 章节目录
  .menu-box {
    display: flex;
    position: absolute;
    right: -540px;
    top: 0;
    transition: all 0.5s;
    .drawer-btn {
      position: relative;
      top: 250px;
      display: flex;
      justify-content: space-between;
      border-radius: 25px 0 0 25px;
      align-items: center;
      padding: 0 12px;
      width: 76px;
      height: 178px;
      background: rgba(0, 0, 0, 0.35);

      cursor: pointer;
      img {
        width: 12px;
        height: 22px;
      }
      .rotate-img {
        transform: rotateY(180deg);
      }
      span {
        display: inline-block;
        font-size: 24px;
        color: #fff;
        line-height: 29px;
        writing-mode: vertical-rl;
      }
    }
    .menu-list {
      width: 540px;
      height: 790px;
      padding: 54px 22px 54px 42px;
      color: rgba(255, 255, 255, 0.9);
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.35);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.52) 0%,
        rgba(0, 0, 0, 0.7) 18%,
        rgba(0, 0, 0, 0.42) 100%
      );
      transition: all 0.5s;
      position: relative;
      .list-name {
        font-size: 28px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
      }

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        // margin:  auto;
      }
    }
  }
  .menu-box-open {
    right: 0;
    .menu-list {
      transition: all 0.5s;
      box-shadow: -30px 4px 50px 1px rgba(0, 0, 0, 0.5);
    }
  }
}
.init-box {
  background-color: #fff;
  padding: 32px 40px;
  margin-top: 16px;
  .title {
    font-size: 24px;
    color: #333;
    line-height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .right {
      color: @theme;
      font-size: 16px;
    }
  }
  .content {
    line-height: 32px;
    color: #333;
    margin-top: 16px;
    font-family: PingFangMedium;
    font-size: 16px;
    padding-bottom: 18px;
    text-align: justify;
  }
  .row {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    padding-left: 32px;
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 24px;
    color: #666;
    .text {
      color: @theme;
      font-family: PingFangMedium;
      width: 4em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 16px;
    }
  }
}
.tabs-box {
  margin-top: 46px;
  background-color: #fff;
  padding: 75px 40px 40px;
  margin-bottom: 50px;
  position: relative;
  .tabs {
    height: 56px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 0 20px;

    .tabsdiv {
      margin-right: 40px;
      // padding: 10px 20px;
      width: 182px;
      height: 64px;
      font-size: 20px;
      font-family: PingFangMedium;
      position: relative;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
      // transition: all 0.3s;
    }
    .a1 {
      background-image: url("../../assets/image/curriculum/20220607-134028.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      // transition: all 0.3s;
      // font-size: 24px;
      color: @theme;
    }
    .a2 {
      background-image: url("../../assets/image/curriculum/20220506-144134.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      // transition: all 0.3s;
      // font-size: 24px;
      color: @theme;
    }
  }
  .content {
    min-height: 300px;
    /deep/img {
      max-width: 100%;
    }
  }
}
.teacher {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  // padding-bottom: 10px;
  padding-left: 25px;
  /deep/.swiper-wrapper {
    padding-bottom: 32px;
    .swiper-slide {
      width: 100% !important;
    }
  }
  /deep/.swiper-pagination-bullets {
    bottom: 0;
  }
  /deep/.swiper-pagination {
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #e5e5e5;
      opacity: 1;
      margin: 0 8px;
    }
    .swiper-pagination-bullet-active {
      background-color: #15b7dd;
    }
  }
  .left {
    position: relative;
    margin-right: 15px;
    margin-bottom: 20px;
    .picture {
      position: relative;
      z-index: 10;
      width: 152px;
      height: 200px;
      background-repeat: no-repeat;
      object-fit: cover;
      border-radius: 16px 16px 0 0;
    }
    .name {
      position: relative;
      z-index: 10;
      width: 152px;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      font-family: PingFangMedium;
      justify-content: center;
      align-items: center;
      background-color: #e5e5e5;
      color: #333333;
      // height: 36px;
      border-radius: 4px;
      margin-top: 10px;
      font-size: 16px;
      padding: 6px 10px;
    }
    .border {
      width: 182px;
      height: 202px;
      border: 1px solid #E5E5E5;
      position: absolute;
      // bottom: -15px;
      top: 56px;
      left: -15px;
    }
    .triangle {
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-left: 24px solid #E5E5E5;
      border-bottom: 12px solid transparent;
      position: absolute;
      bottom: -38px;
      left: -25px;
      opacity: 0.5;
    }
  }
  .titleList {
    padding-left: 40px;
    padding-top: 28px;
    .titleItem {
      margin-bottom: 19px;
      font-size: 20px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: red;
        margin-right: 8px;
      }
    }
    .titleItem:last-child {
      margin: 0;
    }
  }
}
.introduction {
  padding-bottom: 30px;
  background-image: url("../../assets/image/curriculum/4272.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 27px;
  padding-top: 35px;
  font-size: 18px;
  font-family: PingFangMedium;
  font-weight: 400;
  line-height: 36px;
}
.progress {
  height: 0;
  text-align: right;
  color: @theme;
  line-height: 21px;
  position: relative;
  top: -21px;
  img {
    margin-right: 8px;
  }
}
/deep/.video-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  z-index: 0;
  display: none;
  .btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    .btnItem {
      margin: 0 5%;
      pointer-events: auto;
      cursor: pointer;
      .icon {
        text-align: center;
        padding-bottom: 16px;
        img {
          width: 62px;
          height: 62px;
        }
      }
      .btnName {
        white-space: nowrap;
        font-size: 32px;
        line-height: 48px;
      }
    }
  }
}
/deep/.vjs-next,
/deep/.vjs-previous {
  img {
    height: 1.17em;
  }
}
@media screen and (max-width: 16000px) {
 .video-box .menu-box .drawer-btn span{
   font-size: 20px;
 }
 .video-box .menu-box .drawer-btn{
   width: 74px;
   height: 158px;
 }
}
</style>
